import { render, staticRenderFns } from "./removeUsersRequestStat.vue?vue&type=template&id=41e29d56&scoped=true&"
import script from "./removeUsersRequestStat.vue?vue&type=script&lang=ts&"
export * from "./removeUsersRequestStat.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e29d56",
  null
  
)

export default component.exports