















import { ClearUsersRequest, RequestStatus } from "@/includes/types/ClearUsers.types"

import Vue from 'vue'
import { Component, Prop } from "vue-property-decorator";

@Component({
  data() {
    return {
      RequestStatus
    }
  }
})
export default class ClearUsersRequestView extends Vue {
  @Prop() request!: ClearUsersRequest
}
